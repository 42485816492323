import React from 'react';
import App from './src/containers/App';
import { onWindowLoadEvent, performDeviceIdentification } from './src/components/Analytics';
import log from 'loglevel';
import "@fontsource/sarabun/latin-400.css";


export const wrapRootElement = (props) => (
  <App {...props} browser>{props.element}</App>
);

export const onClientEntry = () => {
  log.debug("onClientEntry");
  performDeviceIdentification();
  window.addEventListener("load", onWindowLoadEvent);
};

export const onInitialClientRender = () => {
  log.debug("ReactDOM.render has executed");
};

export const disableCorePrefetching = () => {
  log.debug("disableCorePrefetching() -> return true");
  return true;
};
