import React, { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import log from 'loglevel';
import { getSessionFromStorage, SessionContext } from 'components/Session';
import { LogRocketLazyLoader } from "components/LogRocketLazyLoader";
// import { loadAdCritterScripts, parseAdvertisingQueryParams } from "components/AdCritter";
// import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

const queryClient = new QueryClient();

const App = (props) => {
  const { children, browser } = props;
  log.debug("App::render() props:", props);

  const [ sessionContext, setSessionContextState ] = useState({});
  const setSessionContext = (newSessionContext) => {
    localStorage.setItem("sessionContext", JSON.stringify(newSessionContext));
    setSessionContextState(newSessionContext);
  };

  useEffect(() => {
    const sessionFromStorage = getSessionFromStorage();
    if (Object.keys(sessionFromStorage).length > 0) {
      setSessionContext(sessionFromStorage);
    }
    if (browser) {
      // Only load AdCritter scripts in real browser environment
      // loadAdCritterScripts();
      // parseAdvertisingQueryParams(sessionFromStorage, setSessionContextState);
    }
  }, []);

  return (
    <>
      <SessionContext.Provider value={{ sessionContext, setSessionContext }}>
        <QueryClientProvider client={queryClient}>
          <LogRocketLazyLoader />
          { children }
        </QueryClientProvider>
      </SessionContext.Provider>
      <div className="App">
        {/* <TawkMessengerReact
            propertyId="64730f81ad80445890ef84a0"
            widgetId="1h1gn94mi"/> */}
      </div>
    </>
  );
};

export default App;
