import { useState, useEffect, createContext, useContext } from "react";
import { isBrowser } from "./Browser";


export const getSessionFromStorage = () => {
  if (!isBrowser()) {
    return {};
  }
  return JSON.parse(localStorage.getItem("sessionContext")) || {};
};

export const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = useState(
    JSON.parse(localStorage.getItem(localStorageKey)) || {}
  );

  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(value));
  }, [localStorageKey, value]);

  return [value, setValue];
};

export const SessionContext = createContext({});

export const useSessionContext = () => {
  return useContext(SessionContext);
};
