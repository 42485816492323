import React, { useEffect } from 'react';
import { CODEBUILD_BUILD_NUMBER, LOG_ROCKET_APP_ID } from "api-config";
import { isBrowser } from './Browser';
import LogRocket from 'logrocket';
import { useSessionContext } from "./Session";
import log from 'loglevel';


const LogRocketWrapper = () => {
  const { sessionContext } = useSessionContext();
  log.debug(`LogRocketWrapper:`, sessionContext);
  const { email, name, isAdminUser } = sessionContext.userInfo || {};
  const { acid, utmSource, utmMedium } = sessionContext.advertisingAttribution || {};

  useEffect(() => {
    if (isBrowser()) {
      log.info(`LogRocket::init() ${LOG_ROCKET_APP_ID} ${CODEBUILD_BUILD_NUMBER}`);
      LogRocket.init(LOG_ROCKET_APP_ID, {
        release: CODEBUILD_BUILD_NUMBER
      });
    } else {
      log.info("not initializing LogRocket due to no browser detected.");
    }
  }, []);

  useEffect(() => {
    if (isBrowser()) {
      const traits = {
        email,
        name,
        isAdminUser,
        acid,
        utmSource,
        utmMedium
      };
      log.info(`LogRocket.identify(${email}) traits:`, traits);
      LogRocket.identify(email, traits);
    } else {
      log.info("not calling LogRocket.identify due to no browser detected.");
    }
  }, [email, name, isAdminUser]);

  return (<></>);
};

export {
  LogRocketWrapper
};
