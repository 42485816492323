import React, { Suspense, useEffect, useState } from 'react';
import { isBrowser } from './Browser';
import { LogRocketWrapper } from './LogRocketWrapper';
import log from 'loglevel';
import { ENABLE_LOG_ROCKET_IN_DEVELOPMENT, IS_DEVELOPMENT } from 'api-config.mjs';


const shouldCreateLogRocket = () => {
  if (!isBrowser()) {
    // TODO: Is this actually necessary?
    return true;  // Do this to ensure the component gets bundled at build time
  }
  if (IS_DEVELOPMENT) {
    return ENABLE_LOG_ROCKET_IN_DEVELOPMENT;
  }
  const { userAgent } = navigator;
  log.debug(`Got userAgent: ${userAgent}`);

  if (userAgent?.includes("Firefox")) {
    log.debug("GOT FIREFOX!");
  }
  if (userAgent?.includes("HeadlessChrome")) {
    log.info("Detected Headless Chrome, skipping LogRocket initialization.");
    // Don't load LogRocket if the user agent is a Headless Browser
    return false;
  }
  if (userAgent?.includes("Chrome-Lighthouse")) {
    log.info("Detected Chrome-Lighthouse, skipping LogRocket initialization.");
    // Don't load LogRocket if the user agent is a Lighthouse Audit Report
    return false;
  }
  if (userAgent?.includes("BingPreview")) {
    log.info("Detected BingPreview, skipping LogRocket initialization.");
    // Don't load LogRocket if the user agent is a BingPreview
    return false;
  }

  if (userAgent === "cca-app-tester") {
    log.info("Detected cca-app-tester, skipping LogRocket initialization.");
    // Don't load LogRocket if the user agent is a Lighthouse Audit Report
    return false;
  }

  return true;
};

const LogRocketLazyLoader = () => {

  log.debug("LogRocketLazyLoader::render()");

  const [ createLogRocket, setCreateLogRocket ] = useState(false);

  useEffect(() => {
    if (shouldCreateLogRocket()) {
      log.debug("Creating Log Rocket Wrapper Component..");
      setCreateLogRocket(true);
    }
  }, []);

  if (!createLogRocket) {
    return (<></>);
  }

  return (
    <>
      <Suspense fallback={<></>}>
        <LogRocketWrapper />
      </Suspense>
    </>
  );
};

export {
  LogRocketLazyLoader
};
