import log from 'loglevel';
import { NODE_ENV } from '../api-config.mjs';
import { identifyDevice, pageLoadEvent, recordAnalyticsEvents } from 'api.mjs';
import { getCookie, isBrowser } from './Browser';
import { getSessionFromStorage } from './Session';

// Control analytics on/off when local testing
const DISABLE_ANALYTICS_IN_DEV = false;
const MAX_LOCAL_EVENTS = 50;
const FLUSH_WAIT_TIME = 5000;  // 5 seconds
let events = null;
const pageLoads = [];
let hasWindowLoaded = false;
let flushTimer = null;
let lastPageLoaded = null;
let refs = null;

const decodeReferrerHash = (hash) => {
  if (!hash) {
    return;
  }
  try {
    const decodedString = atob(hash.substring(1));
    const data = JSON.parse(decodedString);
    refs = data.refs;
  } catch (err) {
    log.debug(err);
  }
};

// https://stackoverflow.com/a/54246501
const camelToSnakeCase = str => str[0].toLowerCase() + str.slice(1, str.length).replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

const gTagEvent = (event) => {
  const eventName = camelToSnakeCase(event.name);
  // https://www.gatsbyjs.com/plugins/gatsby-plugin-google-gtag/#custom-events
  // https://developers.google.com/analytics/devguides/collection/ga4/events?client_type=gtag
  window.gtag("event", eventName, event);
};

const sendEventsToGTag = ({ events }) => {
  if (!events || !events.length) {
    return;
  }
  if (typeof window === "undefined" || typeof window.gtag === "undefined") {
    return;
  }
  events.forEach(event => {
    gTagEvent(event);
  });
};

const flushEvents = () => {
  log.debug("flushEvents()");
  if (flushTimer) {
    clearTimeout(flushTimer);
    flushTimer = null;
  }
  localStorage.removeItem("events");
  const { navigator: { userAgent } } = window;
  const sessionContext = getSessionFromStorage();
  recordAnalyticsEvents({ data: { events, refs, userAgent }, context: {sessionContext: sessionContext} })
    .catch(err => {
      log.error("Failed to send analytics events.");
      log.error(err);
    });
  sendEventsToGTag({ events });
  events = [];
};

const recordEvent = (event) => {
  if (!isBrowser()) {
    return;
  }
  if (DISABLE_ANALYTICS_IN_DEV && NODE_ENV === "development") {
    return;
  }
  if (!events) {
    events = JSON.parse(localStorage.getItem("events")) || [];
  }
  log.debug("recordEvent:", event.name, event);
  events.push({ ...event, time: new Date() });
  localStorage.setItem("events", JSON.stringify(events));
  if (events.length >= MAX_LOCAL_EVENTS) {
    flushEvents();
  } else if (!flushTimer) {
    log.debug("setting Background Timer")
    flushTimer = setTimeout(() => {
      flushEvents();
    }, FLUSH_WAIT_TIME);
  }
};

const random = (min = 0, max = 1000000) => {
  let num = Math.random() * (max - min) + min;
  return Math.round(num);
};

// TODO: Add retry logic on analytics/page load events
const callPageLoadApi = ({ path, sessionContext = null, options = {} }) => {
  log.debug(getSessionFromStorage());
  const { document: { referrer }, navigator: { userAgent } } = window;
  pageLoadEvent({ data: { ...options, path, refs, userAgent, referrer }, context: {sessionContext: sessionContext} })
    .catch(err => {
      log.error("Failed to send page load event.");
      log.error(err);
    });
};

const pageLoad = ({ path, hash, sessionContext, options: _options }) => {
  if (!isBrowser()) {
    return;
  }
  if (DISABLE_ANALYTICS_IN_DEV && NODE_ENV === "development") {
    return;
  }
  if (lastPageLoaded === path) {
    return;  // Prevent re-rendering of the same path from triggering this.
  }
  const { advertisingAttribution } = sessionContext;
  const options = {
    ..._options,
    advertisingAttribution,
    adCritterAttribution: advertisingAttribution,
  };
  decodeReferrerHash(hash);
  lastPageLoaded = path;
  log.debug("pageLoad:", path, "referrer:", window.document.referrer);
  if (hasWindowLoaded) {
    callPageLoadApi({ path, sessionContext, options });
  } else {
    // We don't want tracking pixel latency to block page load time.  Therefore, we push them onto an array.
    // They will be fired once the page actually loads.
    log.debug("Initial Page not loaded yet, pushing path:", path);
    pageLoads.push({ path, hash, options });
  }
};

const onWindowLoadEvent = (event) => {
  log.debug("onWindowLoadEvent:", event);
  hasWindowLoaded = true;
  const sessionContext = getSessionFromStorage();
  pageLoads.forEach(({ path, hash, options }) => {
    callPageLoadApi({ path, sessionContext, options });
  });
};

// Called when the Gatsby browser runtime first starts.
const performDeviceIdentification = () => {
  log.debug(`performDeviceIdentification`);
  const deviceIdCookie = getCookie("did");
  if (!deviceIdCookie) {
    log.debug(`No deviceIdCookie cookie found, calling identify`);
    identifyDevice();
  } else {
    log.debug(`deviceIdCookie: ${deviceIdCookie}`);
  }
};

export {
  recordEvent,
  pageLoad,
  onWindowLoadEvent,
  performDeviceIdentification
};
