
export const isBrowser = () => {
    // During nodejs build time, localStorage doesn't exist.
    if (typeof localStorage === 'undefined') {
      return false;
    }
    return true;
};

// https://www.tabnine.com/academy/javascript/how-to-set-cookies-javascript/
export const setCookie = (cName, cValue, expDays) => {
  let date = new Date();
  date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${cName}=${cValue}; ${expires}; path=/`;
};

export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export function deleteCookie(cname) {
  setCookie(cname, getCookie(cname), 0);
};

export const isMobileDevice = () => {
  if (isBrowser()) {
    return window.innerWidth < 991;
  }
  return true;
};
